@import 'stylesheets/variables/spacing.scss';
@import 'stylesheets/variables/breakpoints.scss';
@import 'stylesheets/variables/colors.scss';

.tt-PageTabs {
    position: relative;

    // Scroll container for mobile
    &__scroller {
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;

        // hide scrollbar
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    // Scroll fade
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: -5px;
        bottom: 0;
        width: getSpacing(2);
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), $color-background);
        pointer-events: none;
        z-index: 1;
    }

    // Spacing wrapper
    &__wrapper {
        position: relative;
        padding-top: 10px;
        padding-right: 10px;

        .tt-PageTab {
            + .tt-PageTab {
                margin-left: 4px;
            }

            &--is-icon {
                margin-bottom: 0;
            }
        }
    }

    .tt-List {
        text-align: left;
        white-space: nowrap;

        > .tt-ListItem {
            vertical-align: bottom;
            float: none;
        }
    }

    @media screen and (min-width: $breakpoint-desktop) {
        .tt-List {
            text-align: right;
        }

        &__wrapper {
            .tt-PageTab {
                + .tt-PageTab {
                    margin-left: 7px;
                }

                &--is-icon {
                    margin-bottom: 0;
                }
            }
        }

        &::before {
            display: none;
        }
    }
}
