@import 'stylesheets/variables/breakpoints.scss';
@import 'stylesheets/variables/spacing.scss';

.tt-TimeSlotsTable {
    background-color: #f2f4f5;
    border-radius: 2px;
    padding: getSpacing(2) - 6 getSpacing(2);

    &__header {
        margin-bottom: getSpacing(1);
    }

    &__title {
        margin-right: getSpacing(1);
    }

    @media screen and (min-width: $breakpoint-tablet) {
        &__header {
            display: flex;
            flex-wrap: wrap;

            > * {
                flex: 1 0 50%;
            }
        }

        &__title {
            margin-right: 0;
        }

        &__spots {
            text-align: right;
        }

        &__list-container {
            display: flex;
            flex-wrap: wrap;

            > * {
                flex: 1 0 50%;
            }
        }
    }
}
