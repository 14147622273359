@import 'stylesheets/variables/fonts.scss';
@import 'stylesheets/variables/colors.scss';
@import 'stylesheets/variables/spacing.scss';
@import 'stylesheets/variables/breakpoints.scss';

body {
    display: flex;
    min-height: 100%;
    font-family: $font-primary;
    color: $color-text;
    line-height: 1.43em;
    min-width: 320px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(#000, 0.05);
    -ms-overflow-style: -ms-autohiding-scrollbar;

    #root {
        display: flex;
        flex: 1 1;
        min-height: 100vh; // IE11 height fix, since "flex: 1 1;" wont work in IE11

        .tt-RootView {
            flex: 1 1;

            .tt-CenterView {
                padding: getSpacing(2);
            }
        }
    }

    #Smallchat > iframe {
        transition: width, height, transform !important;
        transition-duration: 200ms !important;
        transition-timing-function: cubic-bezier(0.25, 0.25, 0.5, 1) !important;
        transform: none;
    }

    @media screen and (max-width: ($breakpoint-tablet - 1px)) {
        .tt-Body--modal-active {
            overflow: hidden;

            #app {
                display: none;
            }
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        .tt-Body--modal-active {
            overflow: hidden;

            &.tt-Body--modal-mask-background {
                #app {
                    filter: blur(5px);
                }
            }
        }

        .tt-Body--has-fixed-action-bar {
            .tt-Toastr {
                bottom: getSpacing(1) * 8;
            }

            #Smallchat > iframe {
                transform: translateY(-96px);
            }
        }
    }
}
