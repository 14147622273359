@import 'stylesheets/variables/colors.scss';

.tt-ContentView {
    background-color: $color-white;
    padding-top: 35px;

    &--has-fixed-action-bar {
        padding-bottom: getSpacing(4) + 50px;
    }

    &--inset {
        margin-top: getSpacing(3);
        padding-bottom: getSpacing(4);
        padding-top: getSpacing(4) !important;
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
        background: $color-grey-background;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        padding-top: 0;
    }
}
