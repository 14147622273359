.tt-PdfViewer {
    @import 'stylesheets/variables/spacing.scss';
    @import 'stylesheets/variables/colors.scss';

    padding-bottom: 1px;

    .tt-Spinner {
        margin: getSpacing(2);
    }

    .ReactPDF__Page {
        + .ReactPDF__Page {
            border-top: $border-pdf-page-break;
        }
    }
}
