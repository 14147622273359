/* This stylesheet generated by Transfonter (https://transfonter.org) on April 18, 2018 9:31 AM */

@font-face {
    font-family: 'Ubuntu Mono';
    src: url('fonts/UbuntuMono/UbuntuMono-Regular.eot');
    src: url('fonts/UbuntuMono/UbuntuMono-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/UbuntuMono/UbuntuMono-Regular.woff2') format('woff2'),
        url('fonts/UbuntuMono/UbuntuMono-Regular.woff') format('woff'),
        url('fonts/UbuntuMono/UbuntuMono-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu Mono';
    src: url('fonts/UbuntuMono/UbuntuMono-BoldItalic.eot');
    src: url('fonts/UbuntuMono/UbuntuMono-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/UbuntuMono/UbuntuMono-BoldItalic.woff2') format('woff2'),
        url('fonts/UbuntuMono/UbuntuMono-BoldItalic.woff') format('woff'),
        url('fonts/UbuntuMono/UbuntuMono-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Ubuntu Mono';
    src: url('fonts/UbuntuMono/UbuntuMono-Italic.eot');
    src: url('fonts/UbuntuMono/UbuntuMono-Italic.eot?#iefix') format('embedded-opentype'),
        url('fonts/UbuntuMono/UbuntuMono-Italic.woff2') format('woff2'),
        url('fonts/UbuntuMono/UbuntuMono-Italic.woff') format('woff'),
        url('fonts/UbuntuMono/UbuntuMono-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Ubuntu Mono';
    src: url('fonts/UbuntuMono/UbuntuMono-Bold.eot');
    src: url('fonts/UbuntuMono/UbuntuMono-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/UbuntuMono/UbuntuMono-Bold.woff2') format('woff2'),
        url('fonts/UbuntuMono/UbuntuMono-Bold.woff') format('woff'),
        url('fonts/UbuntuMono/UbuntuMono-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
