.tt-ExportsCard {
    @import 'stylesheets/variables/spacing.scss';
    @import 'stylesheets/variables/breakpoints.scss';

    width: 282px;
    height: 282px;
    border: 1px solid #e1e3e6;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    &__content {
        padding: 18px 18px getSpacing(1);
    }

    &__description {
        overflow-y: scroll;
        margin-top: 10px;
        font-size: 13px;
        max-height: 105px;
    }

    &__footer {
        background-color: #f2f4f5;
        padding: 18px;

        .tt-Button {
            width: 100%;
        }
    }
}
