.tt-PrefixedInput {
    @import 'stylesheets/variables/colors.scss';
    @import 'stylesheets/mixins/input.scss';

    max-width: $input-max-width;

    & &__prefix,
    & &__seperator,
    & &__input {
        display: inline-block;
        vertical-align: top;
    }

    & &__prefix {
        .tt-TagPicker__control {
            width: 120px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }

    & &__input {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        max-width: calc(100% - 120px);
        border-left: 0;
    }

    &:hover.tt-PrefixedInput__seperator {
        border-color: $color-border-input-hover;
    }

    &--has-focus {
        .tt-PrefixedInput__seperator {
            background-color: $color-border-input-focus;
        }
    }
}

.tt-Field--has-error {
    .tt-PrefixedInput {
        .tt-PrefixedInput__seperator {
            background-color: $color-input-border-error;
        }
    }
}
