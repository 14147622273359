.tt-TableCell.tt-RotatedTableHeaderItem {
    @import 'stylesheets/variables/spacing.scss';
    @import 'stylesheets/variables/colors.scss';

    padding-left: getSpacing(2);
    overflow: visible;

    .tt-RotatedTableHeaderItem__rotator {
        position: relative;
        display: inline-block;
        transform: rotate(-45deg);
        transform-origin: 0 100%;
        z-index: 1;
    }
}
