@import 'stylesheets/variables/colors.scss';
@import 'stylesheets/variables/spacing.scss';
@import 'stylesheets/variables/breakpoints.scss';
@import 'stylesheets/animations/showHideNav.scss';

.tt-Header {
    width: 100%;
    min-height: 80px;
    background-color: $color-background;
    border-bottom: 1px solid $color-border;
    z-index: 3;
    position: fixed;
    top: 47px;
    transition: $menu-transition;

    .tt-BreadCrumbs {
        margin-bottom: 0;
        padding: (getSpacing(1.5) - 4px) getSpacing(1.5) 0;
    }

    .tt-PageTabs {
        display: flex;
        align-self: flex-end;
        overflow-x: scroll;
        margin-bottom: -1px;

        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
        }

        .tt-PageTab:first-child {
            margin-left: getSpacing(1.5);
        }
        .tt-PageTab:last-child {
            margin-right: getSpacing(1.5);
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        padding: 14px 20px 0 40px;
        height: unset;
        min-height: 80px;
        display: flex;
        justify-content: space-between;

        .tt-BreadCrumbs {
            padding: 0;
            margin-right: getSpacing(2);
        }

        .tt-PageTabs {
            .tt-PageTab:first-child {
                margin-left: 0;
            }
            .tt-PageTab:last-child {
                margin-right: 0;
            }
        }

        .tt-HeaderActionList {
            float: right;
        }
    }
}
