.tt-Emphasis {
    @import 'stylesheets/variables/colors.scss';

    font-style: italic;

    &--warning {
        font-style: normal;
        color: $color-text-warning;
    }
}
