.tt-ActionBar {
    @import 'stylesheets/variables/spacing.scss';
    @import 'stylesheets/variables/breakpoints.scss';
    @import 'stylesheets/variables/colors.scss';
    @import 'stylesheets/mixins/clearfix.scss';

    &__buttons {
        min-height: 45px;

        .tt-Subtle {
            font-size: 14px;
            padding-top: 9px;
        }

        .tt-ButtonGroup {
            display: inline-flex;
        }

        > .tt-List {
            text-align: left;
            margin: -(getSpacing(1) / 2);

            > .tt-ListItem {
                padding: getSpacing(1) / 2;
                display: block;
                float: none;

                .tt-Subtle {
                    font-size: 14px;
                    padding-top: 9px;
                }
            }
        }

        .tt-Search {
            width: 300px;
            max-width: 180px;
        }

        @include clearfix();
    }

    &__amount-of-results {
        margin-top: 6px;
        font-size: 12px;
        color: $color-text-subtle;
    }

    &--is-drawer-open {
        .tt-ActionBar__drawer {
            margin-top: getSpacing(1);
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        &__buttons {
            .tt-List {
                text-align: right;

                > .tt-ListItem {
                    float: left;

                    &--right {
                        float: right;
                    }
                }
            }

            .tt-Search--has-focus {
                max-width: 250px;
            }
        }

        &--fixed {
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: #ffffff;
            padding: getSpacing(2) getSpacing(3);
            border-top: 1px solid $color-border;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
            transition: box-shadow 0.225s ease;
            z-index: 1;

            .tt-ActionBar__buttons {
                margin-bottom: 0;

                .tt-Link {
                    margin-top: getSpacing(1);
                }
            }
        }

        &--no-shadow {
            box-shadow: none;
        }
    }
}
