.tt-ProjectArticleCodeInput {
    @import 'stylesheets/variables/colors.scss';
    @import 'stylesheets/variables/spacing.scss';
    position: relative;

    .tt-Input {
        padding-right: 35px;
    }

    .tt-Spinner,
    .tt-Icon {
        position: absolute;
        z-index: 1;
        top: 8px;
        right: 8px;
    }

    .tt-Emphasis {
        display: block;
        max-width: 180px;
        font-size: 12px;
        padding-left: 8px;
        line-height: 1.4em;
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .tt-Icon {
        color: $color-green;
        font-size: 14px;
        top: getSpacing(1);
        right: getSpacing(1);

        svg {
            width: auto;
        }
    }
}
