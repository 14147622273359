.tt-CheckBox {
    @import 'stylesheets/variables/spacing.scss';

    display: block;
    cursor: pointer;

    .tt-Input {
        display: none;
    }

    &__box,
    &__text {
        display: inline-block;
        vertical-align: top;
    }

    &__box {
        margin-right: getSpacing(1);
        background-color: #e1e4e6;
        width: 16px;
        height: 16px;
        border-radius: 2px;
        background-color: #fff;
        border: 1px solid #ccd1d5;

        &-check {
            display: block;

            opacity: 0;
            line-height: 16px;
            text-align: center;
            width: 16px;
            font-size: 10px;
            position: relative;
            top: 1px;

            svg * {
                fill: $color-blue-light;
            }
        }
    }

    &__text {
        font-size: 14px;
        line-height: 17px;
    }

    .tt-Input:checked + .tt-CheckBox__box {
        background-color: #fff;

        .tt-CheckBox__box-check {
            opacity: 1;
            top: 0;
            width: 15px;
        }
    }

    &--is-disabled,
    &--is-readonly {
        opacity: 0.5;
        pointer-events: none;

        &:hover {
            .tt-CheckBox__box-check {
                opacity: 0;
            }
        }
    }

    &--is-horizontal {
        display: flex;
        flex-direction: row;

        .tt-CheckBox__text {
            line-height: 1.6;

            &::first-line {
                line-height: 1.4;
            }
        }
    }
}

.tt-Field--has-error {
    .tt-CheckBox {
        .tt-CheckBox__box {
            box-shadow: 0 0 0px 1px $color-input-border-error inset;
        }
    }
}
