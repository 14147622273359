.tt-FileUpload {
    @import 'stylesheets/variables/spacing.scss';
    @import 'stylesheets/variables/colors.scss';

    &--has-value {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;
        border: 1px solid $color-button-border;
        border-radius: 2px;
        max-width: 277px;
        background-color: $color-white;

        > * {
            border: 0;
        }

        .tt-FileUpload__content {
            font-weight: normal;
            flex: 1;
            color: $color-text;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: start;

            &:hover {
                background-color: $color-white;
                cursor: default;
            }

            &:active {
                box-shadow: none;
            }
        }

        .tt-FileUpload__remove-button {
            border-left: 1px solid $color-button-border;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    & + .tt-FileUpload,
    & + .tt-ExistingFile {
        margin-top: getSpacing(0.5);
    }
}
