.tt-BrickTableCell {
    @import 'stylesheets/variables/colors.scss';
    @import 'stylesheets/variables/spacing.scss';

    border-left: 1px solid $color-border;
    box-sizing: border-box;
    width: 61px;
    position: relative;
    height: 32px;

    &__content {
        background-color: rgba(144, 149, 153, 0.3);
        position: absolute;
        top: getSpacing(0.5);
        left: getSpacing(0.5);
        right: getSpacing(0.5);
        bottom: getSpacing(0.5);
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        color: $color-text;
        border-radius: 2px;
        justify-content: center;
        font-size: 16px;

        .tt-Icon {
            margin-right: 0;
        }
    }

    &:last-child {
        border-right: 1px solid $color-border;
    }
}
