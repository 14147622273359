.tt-VariableMultiFieldBlock {
    @import 'stylesheets/variables/colors.scss';

    &--is-inset {
        background-color: $color-background;
    }

    .tt-FieldBlock {
        &__header {
            height: 32px;
        }

        &__remove-button {
            padding: 0 8px;
        }
    }
}
