@import 'stylesheets/variables/colors.scss';

.tt-Brand {
    color: $color-white;
    text-align: center;

    img {
        height: 24px;
        margin-top: 5px;
    }

    h1 {
        position: absolute;
        visibility: hidden;
    }
}
