@import 'stylesheets/mixins/input.scss';

.tt-TextArea {
    min-height: 123px;
    @include input();
    line-height: 1.6em;

    &--is-disabled,
    &--is-readonly {
        @include input-disabled();
        max-height: 123px;
    }
}

.tt-Field--has-error {
    .tt-TextArea {
        @include input-error();
    }
}
