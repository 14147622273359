@import 'stylesheets/variables/colors.scss';

.tt-SubNavLink {
    display: inline-block;
    height: 32px;
    font-size: 12px;

    .tt-Link {
        text-align: left;
        display: block;
        color: $color-white;
        opacity: 0.6;
        height: 32px;
        line-height: 36px;
        font-size: 12px;
        font-weight: 600;

        &--is-active {
            border-bottom: 2px solid $color-border-blue;
            opacity: 1;
        }

        &:hover {
            opacity: 1;
            text-decoration: none;
            .tt-Link__content {
                text-decoration: none;
            }
        }
    }
}
