.tt-AttendanceCell {
    @import 'stylesheets/variables/colors.scss';

    text-align: center;
    border-left: 1px solid $color-border;

    .tt-AttendanceDot {
        position: relative;
        top: 3px;
    }
}
