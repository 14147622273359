@import 'stylesheets/variables/spacing.scss';

.tt-UserMenu {
    padding: (getSpacing(1) - 4) 0;

    & &__version {
        display: none;
    }

    & &__logout-button.tt-Button {
        width: 18px;
        height: 28px;
        margin-top: 4px;
        background: transparent;
        border: none;
        border-radius: 0;
        margin-left: getSpacing(1);
        padding: 0;
        color: $color-blue-dark;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        padding: (getSpacing(1) - 4) 3px;

        & &__version {
            display: inline-block;
            position: absolute;
            right: 236px;
        }

        & &__logout-button.tt-Button {
            margin-left: -2px;
        }

        .tt-Version {
            cursor: default;
            color: rgba(white, 0.3);
            font-size: 13px;
            line-height: 32px;
            opacity: 0;
            pointer-events: none;
            padding-right: getSpacing(2);
            transition: opacity 150ms ease 250ms;
        }

        &:hover {
            .tt-Version {
                pointer-events: auto;
                opacity: 1;
                transition-delay: 500ms;
            }
        }

        .tt-ListItem {
            margin-left: 15px;

            &:first-child {
                margin-left: 0;
            }
        }

        &__icon-list {
            .tt-ListItem {
                margin-left: 8px;
            }
        }
    }
}
