@import 'stylesheets/variables/colors.scss';
@import 'stylesheets/variables/spacing.scss';

.tt-MainNavLink {
    display: inline-block;

    &--right {
        float: none;
    }

    .tt-Link {
        text-align: left;
        color: $color-blue-dark;
        opacity: 0.6;
        display: block;
        padding: (getSpacing(1) - 4) 0;
        line-height: 36px;
        font-weight: 600;
        font-size: 14px;

        &:hover {
            text-decoration: none;
            opacity: 1;

            .tt-Link__content {
                text-decoration: none;
            }
        }

        &--is-active {
            opacity: 1;
            background-color: $color-primary-active;
        }
    }

    &--mobile-menu-button {
        .tt-Link {
            opacity: 1;
        }
    }
}
