.tt-ScoreInput {
    @import 'stylesheets/variables/colors.scss';
    @import 'stylesheets/mixins/input.scss';

    display: inline-block;
    max-width: 178px;
    position: relative;

    .tt-Input {
        width: auto;
        max-width: none;
    }

    &__level {
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
        font-weight: 400;
        pointer-events: none;
    }
}
