.tt-Notification {
    @import 'stylesheets/variables/colors.scss';

    font-size: 14px;
    padding: getSpacing(1) getSpacing(1.5);
    background: $color-toast-info;
    color: $color-toast-info-text;
    border-radius: 2px;
    font-weight: 600;
    border: none;
    text-align: left;
    line-height: 1.4em;

    &--error {
        background-color: $color-toast-danger;
        color: $color-toast-danger-text;
    }

    &--warning {
        background-color: $color-toast-warning;
        color: $color-toast-warning-text;
    }

    &--success {
        background-color: $color-toast-success;
        color: $color-toast-success-text;
    }

    &--is-clickable {
        cursor: pointer;

        &:hover {
            opacity: 0.9;
        }
    }
}
