.tt-AttendanceTotalCell {
    @import 'stylesheets/variables/spacing.scss';
    @import 'stylesheets/variables/colors.scss';

    width: 60px;
    border-left: 1px solid $color-border;
    text-align: center;
    padding-left: getSpacing(1);
    padding-right: getSpacing(1);
    background-color: $color-background;
}
