.tt-PasswordInput {
    @import 'stylesheets/variables/colors.scss';
    @import 'stylesheets/variables/spacing.scss';

    position: relative;

    &__strength {
        display: block;
        font-size: 12px;
        color: #8d9ebd;
    }

    &__strength-value {
        font-weight: 500;
    }

    &__warning {
        display: block;
        font-size: 12px;
    }

    &__strength-bar {
        display: block;
        position: relative;
        max-width: 490px;
        height: 6px;
        background-color: #f2f4f5;
        margin-left: 1px;
        margin-right: 1px;
        margin-top: -7px;
        border-radius: 1px;
        z-index: 10;
        pointer-events: none;
        overflow: hidden;
    }

    &__strength-bar-inner {
        transform: translateX(-100%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 6px;
    }

    &--strength-very-weak,
    &--strength-weak {
        .tt-PasswordInput__strength-value {
            color: $very-weak-password-strenght-color;
        }
        .tt-PasswordInput__strength-bar-inner {
            background-color: $very-weak-password-strenght-color;
        }
    }
    &--strength-medium,
    &--strength-strong {
        .tt-PasswordInput__strength-value {
            color: $weak-password-strength-color;
        }
        .tt-PasswordInput__strength-bar-inner {
            background-color: $weak-password-strength-color;
        }
    }

    &--strength-very-strong {
        .tt-PasswordInput__strength-value {
            color: $good-password-strength-color;
        }
        .tt-PasswordInput__strength-bar-inner {
            background-color: $good-password-strength-color;
        }
    }

    &--strength-very-weak,
    &--strength-weak,
    &--strength-medium,
    &--strength-strong,
    &--strength-very-strong {
        + .tt-ErrorLabel {
            margin-top: getSpacing(1);
        }
    }
}
