@import 'stylesheets/variables/spacing.scss';

.tt-SimpleTableRow {
    &--header {
        .tt-SimpleTableCell {
            padding-bottom: getSpacing(1);
        }
    }

    &--is-double-table-row {
        .tt-SimpleTableCell:nth-child(2) {
            padding-right: getSpacing(4);
        }
    }
}
