.tt-ObligedCitizenshipEducationEndDatesInput {
    @import 'stylesheets/variables/spacing.scss';

    &__original-date {
        display: flex;
        flex-direction: row;
        align-items: center;

        .tt-DatePicker {
            width: 150px;
        }

        .tt-Button {
            margin-left: getSpacing(0.5);
        }
    }
}
