@import 'stylesheets/variables/spacing.scss';
@import 'stylesheets/variables/colors.scss';

.tt-AttendanceTableHeader {
    opacity: 1;
    transition: 0.3s opacity;

    .tt-TableHeaderItem {
        &:first-child {
            width: 186px;
        }
    }

    .tt-TableHeader__row {
        border-bottom: 2px solid $color-grey;

        .tt-TableCell {
            &--is-sticky {
                z-index: 2;
                background-color: #fff;
            }
        }

        .tt-Link {
            color: $color-blue-dark;
        }
    }

    &--fixed {
        position: fixed;
        padding-top: 204px;
        top: 0;
        z-index: 2;
        background-color: #fff;
        width: 100%;

        .tt-TableHeaderItem {
            max-width: 61px;

            &:first-child {
                width: 186px;
                max-width: 186px !important;
            }
        }
    }

    &--hidden {
        opacity: 0;
        z-index: -1;
    }
}
