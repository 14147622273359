.tt-ObligedCitizenshipEducationEndDateExtension {
    @import 'stylesheets/variables/spacing.scss';

    margin-top: getSpacing(1);
    background-color: #e1e3e6;
    padding: getSpacing(1);
    border-radius: 3px;

    + .tt-ObligedCitizenshipEducationEndDateExtension {
        margin-top: getSpacing(1.5);
    }

    &__date {
        text-decoration: line-through;
    }

    &__title,
    &__description {
        display: block;
    }

    &__footer {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &--is-last-extension {
        .tt-ObligedCitizenshipEducationEndDateExtension__date {
            text-decoration: none;
        }
    }
}
