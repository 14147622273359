.tt-Row {
    @import 'stylesheets/variables/spacing.scss';
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    .tt-Button--is-small {
        margin-top: 0px;
    }

    &--horizontal-center {
        justify-content: center;
    }

    &--has-spacing-between {
        > * + * {
            margin-left: getSpacing(1);
        }
    }

    &--horizontal-end {
        justify-content: flex-end;
    }
}
