.tt-HeaderTitle {
    @import 'stylesheets/variables/spacing.scss';

    font-weight: 400;
    font-size: 22px;

    .tt-CrumbLabel {
        margin-top: 3px;
        margin-bottom: 3px;
        margin-left: getSpacing(1);
        vertical-align: top;
    }
}
