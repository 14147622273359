.tt-TableCell.tt-ResultCell {
    @import 'stylesheets/variables/colors.scss';
    @import 'stylesheets/variables/spacing.scss';

    border-left: 1px solid $color-border;
    box-sizing: border-box;
    width: 60px;
    padding: 1px 5px;
    height: 42px;
    vertical-align: top;
    position: relative;

    &:last-child {
        border-right: 1px solid $color-border;
    }

    .tt-ResultCell__content {
        display: block;
    }

    .tt-ResultCell__sub {
        display: block;
        font-size: 12px;
        margin-top: 2px;
        margin-left: 0;
    }

    &--is-not-scheduled::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: $color-background-table;
    }
}
