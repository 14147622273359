@import 'stylesheets/variables/breakpoints.scss';

.tt-DrawerModal {
    background-color: #fff;
    width: calc(100% - 80px);
    margin-top: 40px;
    margin-left: 40px;

    &__content {
        padding-bottom: 96px;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        min-height: calc(100% - 40px);
    }
}
