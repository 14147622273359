.tt-CrumbLabel {
    @import 'stylesheets/variables/colors.scss';

    display: inline-block;
    padding: 0 6px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    color: $color-label-inverse;
    background-color: $backgroundcolor-label-inverse;
}
