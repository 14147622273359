.tt-DividerDot {
    @import 'stylesheets/variables/spacing.scss';

    margin: 0 (getSpacing(1) - 6);
    border-radius: 50%;
    background-color: #c4c8cc;
    height: 3px;
    width: 3px;
    display: inline-block;
    margin-bottom: 2px;
}
