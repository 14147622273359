.tt-ExistingFile {
    @import 'stylesheets/variables/spacing.scss';
    @import 'stylesheets/variables/colors.scss';

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid $color-button-border;
    overflow: hidden;
    border-radius: 2px;
    max-width: 277px;
    background-color: $color-white;

    > * {
        border: 0;
    }

    &__item {
        font-weight: normal;
        flex: 1;
        color: $color-link;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: start;
    }

    &__remove-button {
        border-left: 1px solid $color-button-border;
        padding-left: 10px;
        padding-right: 10px;
    }

    & + .tt-FileUpload,
    & + .tt-ExistingFile {
        margin-top: getSpacing(0.5);
        margin-top: getSpacing(0.5);
    }
}
