@import 'stylesheets/variables/colors.scss';

.tt-InputIcon {
    position: relative;

    .tt-Input {
        padding-left: 41px;

        &:focus + .tt-InputIcon__icon {
            color: darken($color-border-input-focus, 20%);
        }
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 41px;
        text-align: center;
        line-height: 41px;
        color: #909599;

        .tt-Icon {
            vertical-align: middle;
        }
    }
}
