@import 'stylesheets/variables/breakpoints.scss';
@import 'stylesheets/variables/spacing.scss';

.tt-Form {
    width: 100%;

    @media screen and (max-width: ($breakpoint-tablet - 1px)) {
        .tt-Field {
            margin-bottom: 0;

            &__title,
            &__content {
                width: 100%;
            }

            &__title {
                line-height: normal;
                padding-bottom: 0;
            }
        }

        .tt-FieldCollectionFooter {
            .tt-ListItem:not(.tt-ListItem--right) {
                float: none;
                text-align: left;
                display: block;
            }
        }
    }
}
