.tt-NotificationManager {
    @import 'stylesheets/variables/spacing.scss';

    .tt-Notification {
        position: fixed;
        z-index: 9999;
        max-width: 300px;
        transform: none;
        will-change: transform;
        bottom: getSpacing(9.5);
        right: 0;
        margin-right: getSpacing(1);

        &--enter {
            will-change: transform;
            transform: translate(120%, 0);

            &-active {
                transform: none;
                transition: opacity 225ms ease-in, transform 225ms ease-out;
            }
        }

        &--exit {
            transform: none;

            &-active {
                transition: opacity 200ms ease-in, transform 225ms ease-in;
                transform: translate(120%, 0);
            }
        }
    }
}
