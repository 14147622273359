.tt-ExamProgressCell {
    @import 'stylesheets/variables/colors.scss';
    @import 'stylesheets/variables/spacing.scss';

    border-left: 1px solid $color-border;
    box-sizing: border-box;
    width: 61px;
    position: relative;
    height: 32px;

    &__content {
        position: absolute;
        top: getSpacing(0.5);
        left: getSpacing(0.5);
        right: getSpacing(0.5);
        bottom: getSpacing(0.5);
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        border-radius: 2px;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;

        .tt-Icon {
            margin-right: 0;
        }
    }

    &:last-child {
        border-right: 1px solid $color-border;
    }

    &--no-show,
    &--failed {
        .tt-ExamProgressCell__content {
            .tt-Icon {
                padding-top: 2px;
            }
        }
    }

    &--failed {
        $color-failed: $color-red;

        .tt-ExamProgressCell__content {
            background-color: rgba(165, 49, 53, 0.2);
            color: $color-failed;
        }
    }

    &--no-show {
        $color-no-show: #909599;

        .tt-ExamProgressCell__content {
            background-color: rgba(144, 149, 153, 0.2);
            color: $color-no-show;
        }
    }

    &--exempt {
        $color-no-show: #b68b2d;

        .tt-ExamProgressCell__content {
            background-color: rgba(182, 139, 45, 0.2);
            color: $color-no-show;
        }
    }

    &--passed {
        $color-passed: $color-green;

        .tt-ExamProgressCell__content {
            background-color: rgba(44, 196, 114, 0.2);

            .tt-Icon {
                color: $color-passed;
            }
        }
    }

    &--no-result {
        $color-no-result: #2490e6;

        .tt-ExamProgressCell__content {
            background-color: rgba(36, 144, 230, 0.2);
            color: $color-no-result;
        }
    }

    &--is-blocked {
        background-color: #f7f9fa;
        border-bottom: 1px solid #f7f9fa;
    }
}
