.tt-Search {
    @import 'stylesheets/variables/spacing.scss';

    position: relative;
    transition: max-width;
    transition-duration: 250ms;

    &__icon {
        display: block;
        margin-top: 2px;
        transition: opacity;
        transition-duration: 200ms;
    }

    &__submit {
        position: absolute;
        visibility: hidden;
        pointer-events: none;
    }

    .tt-Spinner {
        position: absolute;
        left: getSpacing(1);
        top: 50%;
        margin-top: -13px;
        opacity: 0;
        transition: opacity;
        transition-duration: 200ms;
    }

    &--is-loading {
        .tt-Search__icon {
            opacity: 0;
        }

        .tt-Spinner {
            opacity: 1;
        }
    }

    &__clear-button.tt-Button {
        position: absolute;
        right: getSpacing(1);
        top: 50%;
        margin-top: -8px;
        border: 0;
        padding: 0;
        height: auto;
        opacity: 0;
        transform: translateX(10px);
        pointer-events: none;

        .tt-Icon {
            color: $color-grey;
        }

        &:hover {
            background-color: transparent;

            .tt-Icon {
                color: $color-grey-subtle;
            }
        }

        &:active {
            box-shadow: none;
        }
    }

    &--has-value {
        .tt-Search__clear-button.tt-Button {
            opacity: 1;
            transform: none;
            pointer-events: auto;
        }

        .tt-Input {
            $clear-button-width: 10px;
            padding-right: getSpacing(2) + $clear-button-width;
        }
    }

    @media screen and (min-width: $breakpoint-desktop) {
        &--has-focus {
            max-width: 250px;
        }
    }
}
