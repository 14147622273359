.tt-PageTab {
    @import 'stylesheets/variables/spacing.scss';
    @import 'stylesheets/variables/colors.scss';
    @import 'stylesheets/variables/breakpoints.scss';

    position: relative;

    .tt-Link {
        display: block;
        padding: getSpacing(0.5) getSpacing(1);
        background-color: $color-background;
        height: 35px;
        font-size: 12px;
        font-weight: 500;
        position: relative;
        color: $color-blue-dark;
        border: 1px solid transparent;
        border-bottom: 1px solid $color-border;

        .tt-Icon--status_attention,
        .tt-Icon--flag-error {
            position: relative;
            top: 1px;
            margin-left: 0;
            margin-top: -5px;
            font-size: 10px;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.4);
            border: 1px solid $color-border;

            .tt-Link__content {
                text-decoration: none;
            }
        }

        &--is-active {
            background-color: $color-white;
            border-radius: 2px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border: 1px solid $color-border;
            border-bottom-color: transparent;
            color: $color-blue-dark;
            font-weight: 600;
            cursor: default;

            &:hover {
                background-color: $color-white;
                border-bottom-color: transparent;

                .tt-Link__content {
                    color: $color-text;
                }
            }
        }
    }

    &__counter {
        position: absolute;
        display: block;
        top: -6px;
        right: -6px;
        width: 16px;
        height: 16px;
        background-color: #e77d57;
        color: white;
        font-size: 9px;
        font-weight: bold;
        border-radius: 50%;
        text-align: center;
        line-height: 16px;
    }

    &--is-icon {
        .tt-Link {
            // color: $color-text-subtle;
            width: 30px;
            height: 30px;
            padding: 0;
            padding-top: 6px;
            text-align: center;

            &.tt-Link--is-active {
                border-bottom-color: $color-border;

                .tt-Link__content {
                    // color: $color-text-subtle;
                }
            }
        }

        .tt-Icon {
            font-size: 13px;
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        .tt-Link {
            padding: 9px 16px;
            height: 41px;
            font-size: 12px;
        }

        &--is-icon {
            .tt-Link {
                width: getSpacing(3);
                height: getSpacing(3);
                padding: 0;
                padding-top: 6px;
            }

            .tt-Icon {
                font-size: 16px;
            }
        }
    }
}
