.tt-GroupLearnersSelectionTable {
    @import 'stylesheets/variables/colors.scss';
    @import 'stylesheets/variables/spacing.scss';

    .tt-GroupLearnersSelectionTableRow__levels {
        span {
            display: inline-block;
            padding: 0 6px;

            + span {
                border-left: 1px solid darken($color-border, 15%);
            }

            &.tt-GroupLearnersSelectionTableRow__levels--is-empty {
                color: $color-text-subtle;
            }
        }
    }
}
