.tt-FieldCollectionFooter {
    @import 'stylesheets/variables/spacing.scss';
    @import 'stylesheets/variables/colors.scss';
    @import 'stylesheets/variables/breakpoints.scss';

    padding: getSpacing(3) 0;
    width: 100%;

    .tt-List {
        margin: -(getSpacing(1) / 2);

        .tt-ListItem {
            padding: getSpacing(1) / 2;
        }
    }

    .tt-ListItem {
        .tt-Link {
            line-height: 40px;
        }
        .tt-CheckBox {
            margin-top: 9px;
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        &--push-left {
            width: auto;
        }
    }
}
