.tt-TableCell {
    @import 'stylesheets/variables/colors.scss';
    @import 'stylesheets/variables/spacing.scss';

    position: relative;
    padding: getSpacing(1) getSpacing(1.5);
    height: 49px;
    color: #909599;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .tt-Icon--check {
        position: relative;
        top: 2px;
        font-size: 1em;
        color: #c4c9cc;
        margin-right: getSpacing(1);
    }

    &--has-inline-icon .tt-Icon {
        position: relative;
        top: 3px;
        margin-left: getSpacing(1);
        margin-right: getSpacing(1);
    }

    .tt-TableCell--inline-form-state {
        position: absolute;
        right: 100%;
        top: 50%;
        margin-top: -9px;
        opacity: 0;
        transform: translateX(10px);
        transition: opacity, transform;
        transition-duration: 200ms;
    }

    &:first-child {
        color: $color-text;

        .tt-Link__content {
            font-weight: 600;
        }
    }

    .tt-TableRow__expansion &:first-child {
        .tt-Link__content {
            font-weight: normal;
        }
    }

    .tt-InvoiceStatusAction {
        vertical-align: top;

        > .tt-ToolTip .tt-Icon,
        > .tt-Icon {
            margin-top: 3px;
            margin-bottom: -9px;
        }
    }

    .tt-Icon--nesting {
        color: $color-grey-subtle;
        margin-right: 5px;
    }

    .tt-Label:not(:first-child) {
        margin-left: 12px;
    }

    .tt-Subtle {
        margin-left: 6px;
    }

    .tt-Button--is-small {
        margin-top: -5px;
        margin-bottom: -3px;
    }

    .tt-TableRow__expansion & {
        .tt-Button--is-small {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .tt-TableRow__expansion & {
        .tt-Button {
            margin-top: getSpacing(1);
        }
    }

    > .tt-Icon--pause {
        position: relative;
        top: 4px;
        margin-left: 11px;
    }

    &--inline-statuses {
        &__divider {
            margin: 0 (getSpacing(1) - 6) 6px;
            border-radius: 50%;
            background-color: #c4c8cc;
            height: 3px;
            width: 3px;
            display: inline-block;
        }

        .tt-InvoiceStatusAction + .tt-InvoiceStatusAction {
            margin-left: getSpacing(1) - 4;
        }
    }

    &--has-rowspan {
        white-space: normal;
    }

    &--has-line-through {
        text-decoration: line-through;
    }

    &--is-sticky {
        position: sticky !important;
        left: 0;
        background-color: $color-background;
        z-index: 1;
        text-align: left;
        transition: 0.5s all;
    }

    &--is-smicky {
        font-size: 10px !important;
    }

    &--is-small {
        transform: translateX(-110px);
        text-align: right;
    }

    &--for-icon-button {
        width: 50px; // icon button width
    }

    &--for-multi-row {
        overflow: visible;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: -18px;
            top: -1px;
            z-index: 2;
            height: 1px;
            background-color: $color-white;
        }
    }

    &--has-inline-state {
        .tt-TableCell--inline-form-state {
            opacity: 1;
            transform: none;

            .tt-Icon--times {
                color: $color-toast-danger;
            }

            .tt-Icon--check {
                color: $color-toast-success;
            }
        }
    }

    &--has-multi-input {
        .tt-MultiInput {
            .tt-ListItem {
                margin-bottom: 0;
            }
        }
    }

    &--has-overflow {
        overflow: visible;
    }

    &--is-loading {
        text-align: center;

        .tt-Spinner {
            display: inline-block;
        }
    }

    &--contains-number {
        text-align: right;
    }

    &--is-highlighted {
        color: $color-text-danger;
    }
}
