.tt-NotesList {
    @import 'stylesheets/variables/breakpoints.scss';
    @import 'stylesheets/variables/spacing.scss';

    $padding: getSpacing(2);
    margin: -($padding / 2);

    > .tt-ListItem {
        box-sizing: content-box;
        display: inline-flex;
        vertical-align: top;
        $size: 284px;
        width: $size;
        height: $size;
        padding: ($padding / 2);

        .tt-Note,
        .tt-PlaceholderNote {
            flex: 1 1;
        }
    }
}
