.tt-SimpleTable {
    @import 'stylesheets/variables/spacing.scss';

    + .tt-SimpleTable {
        margin-top: getSpacing(2);
    }

    .tt-SimpleTableRow {
        + .tt-SimpleTableRow {
            > .tt-SimpleTableCell {
                padding-top: getSpacing(0.5);
            }
        }
    }
}
