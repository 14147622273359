.tt-FileInput {
    @import 'stylesheets/variables/spacing.scss';

    display: table-row;

    .tt-Input {
        position: absolute;
        width: 0;
        padding: 0;
        visibility: hidden;
        pointer-events: none;
    }

    &__button,
    &__filename {
        display: table-cell;
    }

    &__button {
        white-space: nowrap;
    }

    &__filename {
        padding-left: getSpacing(1);
    }

    &__delete-button {
        margin-left: 24px;
    }
}
