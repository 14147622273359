.tt-Wrap {
    @import 'stylesheets/variables/spacing.scss';

    padding: 0 getSpacing(2);
    margin: 0 auto;

    &--full {
        margin-top: getSpacing(2);
    }

    &--is-inset {
        background-color: #f7f9fa;

        &.tt-Wrap {
            margin-top: 0;
            padding-top: getSpacing(3);
            padding-bottom: getSpacing(3);
        }
    }

    > .tt-ActionBar + .tt-Highlight {
        margin-top: getSpacing(1);
    }

    @media screen and (min-width: $breakpoint-tablet) {
        padding: 0 getSpacing(3);
        max-width: 976px + (getSpacing(3) * 2);

        &--full {
            margin-top: getSpacing(3);
            max-width: none;
        }

        &--is-inset {
            &.tt-Wrap {
                margin-top: 0;
            }
        }
    }
}
