.tt-Subtle {
    @import 'stylesheets/variables/colors.scss';
    @import 'stylesheets/variables/spacing.scss';

    display: inline-block;
    color: $color-text-subtle;
    font-size: 12px;

    &--as-suffix {
        margin-left: getSpacing(1);
    }

    &--is-block-element {
        display: block;
    }

    &--is-regular-size {
        font-size: 14px;
    }
}
