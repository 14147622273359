.tt-VariableMultiField {
    @import 'stylesheets/variables/spacing.scss';

    border-top: unset !important;
    padding: unset !important;

    &::before {
        content: none !important;
    }

    & + * {
        margin-top: getSpacing(1);
    }
}
