@import 'stylesheets/variables/spacing.scss';
// adding style to amplify ui
:root {
    --amplify-primary-color: #009060;
    --amplify-primary-tint: #009060;
    --amplify-primary-shade: #009060;
}

.tt-CoverView {
    background-color: $color-cover-background;
    border: 15px solid $color-green;

    .tt-Brand {
        img {
            width: 120px;
            height: auto;
        }
    }

    .tt-CoverPageTitle {
        color: $color-blue-dark;
        padding: getSpacing(2) 0 0;
        font-weight: 600;

        & + .tt-FieldCollection {
            .tt-FieldGroup--is-form {
                padding-top: 0;
            }
        }
    }

    .tt-FieldCollection .tt-FieldGroup--is-form:first-child {
        padding-top: getSpacing(3) - 20px;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        border: 40px solid $color-green;
    }
}
