@import 'stylesheets/variables/colors.scss';
@import 'stylesheets/variables/spacing.scss';
@import 'stylesheets/variables/breakpoints.scss';

.tt-MainNav {
    width: 100%;
    height: 48px;
    background-color: $color-nav-main;
    padding: 0px 13px 0 24px;
    z-index: 10;

    .tt-Icon--menu {
        font-size: 14px;
    }

    .tt-List {
        height: 100%;

        .tt-ListItem {
            vertical-align: middle;
        }

        > .tt-MainNavLink {
            display: none;

            &--mobile-menu-button {
                padding: 0 getSpacing(0.5);
                display: inline-block;
            }
        }
    }

    .tt-Brand {
        position: absolute;
        left: 0;
        right: 0;
        z-index: -1;
    }

    .tt-ExpandableMenu {
        display: none;
        float: left;
    }

    .tt-ExpandableMenu__dropdownlist {
        li {
            float: left;
            clear: both;
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        .tt-ExpandableMenu {
            display: inline-flex;
            flex-direction: column;
            height: 100%;
            position: relative;
        }

        .tt-List {
            > .tt-MainNavLink {
                display: inline-block;

                &--mobile-menu-button {
                    display: none;
                }

                .tt-Link {
                    padding: 6px 16px;
                }
            }
        }

        .tt-ExpandableMenu__dropdownlist {
            .tt-MainNavLink {
                .tt-Link {
                    padding: 0 !important;
                }
            }
        }

        .tt-Brand {
            position: relative;
        }
    }
}
