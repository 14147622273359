@import 'stylesheets/variables/colors.scss';
@import 'stylesheets/variables/spacing.scss';
@import 'stylesheets/variables/breakpoints.scss';

.tt-ExpandableMenu {
    font-size: 10px;
    font-weight: 600;
    color: $color-blue-dark;

    position: relative;

    span {
        display: flex;
        cursor: pointer;
        flex: 1;

        .tt-Icon {
            color: $color-blue-dark;
        }

        .tt-Icon--chevron-down-small {
            margin-left: 3px;

            svg {
                height: 10px;
            }
        }

        .tt-Icon--extra {
            margin-left: 17px;
            margin-right: 15px;
            display: flex;
            align-items: center;

            svg {
                height: 16px;
            }
        }
    }

    &__dropdownlist {
        color: inherit;
        min-width: 160px;
        top: 43px;
        background-color: $color-white;
        position: absolute;
        padding: 19px 16px;
        border-radius: 1px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #e1e3e6;

        li {
            padding-bottom: 15px;

            &:last-of-type {
                padding-bottom: 0;
            }

            .tt-Link {
                color: inherit;
                line-height: unset;
                float: none;
            }
        }
    }

    &--is-open {
        span {
            .tt-Icon {
                transform: translateY(2px) rotate(180deg);
            }
        }
    }

    &__line {
        border-top: 1px solid $color-light-perrywinkle;
        float: none !important;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        display: inline-flex;
    }
}
