@import 'stylesheets/variables/colors.scss';
@import 'stylesheets/variables/spacing.scss';
@import 'stylesheets/variables/breakpoints.scss';

.tt-TableCellDetail {
    position: relative;

    &__list {
        color: $color-text;
        margin-top: -5px;

        .tt-Field {
            &__title {
                width: 15%;
                min-width: 150px;
                font-weight: 600;
                color: $color-text;
                padding-bottom: 0;
            }

            &__content {
                width: 85%;
                padding-bottom: 0;
            }
        }
    }

    &__actions {
        .tt-ListItem:not(:first-of-type) {
            margin-left: getSpacing(1);
        }

        .tt-Link {
            font-size: 12px;

            &__content {
                font-weight: normal !important; // Because the specificty on .tt-TableCell:first-child .tt-Link__content overwrite this
            }

            &:hover {
                color: $color-link;
            }
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        &__actions {
            position: absolute;
            right: getSpacing(1);
            bottom: 0;
        }
    }
}
