.tt-FileUploadInput {
    @import 'stylesheets/variables/spacing.scss';

    .tt-Input {
        position: absolute;
        width: 0;
        padding: 0;
        visibility: hidden;
        pointer-events: none;
    }

    &--in-row {
        position: relative;
        width: 100%;
        height: 100%;

        .tt-FileUpload:not(.tt-FileUpload--has-value) {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}
