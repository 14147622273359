.tt-Calendar {
    @import 'stylesheets/variables/spacing.scss';
    position: relative;

    /**
     * TYPE DEFINITIONS
     */
    $possible-types: (
        BACKGROUND_EVENT: (
            event-color: $color-grey,
        ),
        LEARNER_UNAVAILABILITY: (
            event-color: #e95156,
        ),
        TEACHER_AVAILABILITY: (
            event-color: #2bc572,
        ),
        LESSON_DATE: (
            event-color: lighten(#3f64a4, 15%),
        ),
        ROOM_UNAVAILABILITY: (
            event-color: #e95156,
        ),
        HOLIDAY: (
            event-color: #e95156,
        ),
    );
    overflow-x: auto;

    .tt-Spinner {
        position: absolute;
        top: 150px;
        left: 50%;
        margin-left: -17px;
        opacity: 0;
        z-index: 1;
        pointer-events: none;
        transition: opacity 200ms;
    }

    .fc {
        position: relative;
        z-index: 0;
        transition: opacity 200ms;
        word-break: break-word;

        .fc-time {
            font-size: 10px;
        }

        .fc-title {
            font-size: 10px;
            font-weight: 600;
            vertical-align: top;
        }

        th,
        td,
        .fc-list-view {
            border-color: $color-border;
        }

        .fc-bg .fc-today,
        .fc-day-header.fc-today {
            background-color: rgba(#4d6ea9, 0.04);
        }

        .fc-day-grid .fc-row {
            min-height: 2.5em;

            .fc-content-skeleton {
                padding-bottom: 0;
            }
        }

        .fc-today {
            color: #4d6ea9;

            .fc-day-number {
                font-weight: 600;
            }
        }

        .fc-highlight {
            background-color: #4d6ea9;
            opacity: 0.04;
        }

        .fc-list-table {
            font-size: 14px;
        }

        .fc-list-heading td {
            font-weight: 600;
        }

        .fc-list-item {
            cursor: pointer;
        }

        .fc-scroller {
            &::-webkit-scrollbar {
                width: 0;
            }
        }

        .fc-toolbar {
            position: relative;
            height: 41px;
            margin-bottom: getSpacing(4);

            h2 {
                font-size: 12px;
                margin-top: 6px;
            }

            .fc-center {
                display: block;
            }

            .fc-left,
            .fc-right {
                bottom: 0;
            }

            .fc-left {
                left: 0;
            }

            .fc-right {
                right: 0;
            }

            $button-radius: 2px;
            .ui-button {
                position: relative;
                padding: (getSpacing(1) - 1px) getSpacing(1.5);
                height: 41px;
                border-radius: $button-radius;
                font-size: 14px;
                font-family: $font-primary;
                line-height: 1em;
                background-color: $color-button;
                border: 1px solid $color-button-border;
                font-weight: 600;
                color: $color-button-text;
                cursor: pointer;
                transition-duration: 200ms;
                transition-property: opacity, padding;

                &.ui-state-active,
                &:hover {
                    background-color: #f3f4f5;
                    border: solid 1px $color-grey-subtle;
                }

                &:active {
                    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.3);
                }

                &.fc-add-button {
                    color: $color-button-text-inverted;
                    background-color: $color-button-edit;
                    border-color: $color-button-border-edit;
                    &:hover {
                        background-color: $color-button-edit-hover;
                    }
                }
            }

            .fc-button-group .ui-button {
                border-radius: 0;

                &:first-child {
                    border-top-left-radius: $button-radius;
                    border-bottom-left-radius: $button-radius;
                }
                &:last-child {
                    border-top-right-radius: $button-radius;
                    border-bottom-right-radius: $button-radius;
                }
            }

            .fc-prev-button,
            .fc-next-button {
                width: 31px;
                height: 32px;
                padding: 0;

                .ui-icon {
                    @mixin icon {
                        content: '';
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-position: center;
                        opacity: 0.5;
                    }

                    &-circle-triangle-w {
                        &::after {
                            @include icon;
                            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjE0NDc3IDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMTQ0ODIgOGw2LTV2MTB6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
                        }
                    }

                    &-circle-triangle-e {
                        &::after {
                            @include icon;
                            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjE0NDc3IDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMTQ0ODggOGwtNiA1VjN6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
                        }
                    }
                }
            }

            .fc-today-button,
            .fc-listWeek-button {
                font-size: 12px;
                height: 32px;
                padding: 0 10px;
            }
        }

        .fc-head-container {
            border-bottom-width: 2px;
        }

        .fc-day-header {
            font-weight: 600;
            font-size: 14px;
            padding: 6px;
            border-bottom-width: 2px;
        }

        .fc-divider {
            padding: 0;
            border: 0;
            border-bottom: 3px solid $color-border;
        }

        .fc-axis {
            padding: 7px 13px 10px 11px;
            font-size: 14px;
            color: $color-text-subtle;
        }

        .fc-event-container {
            margin: 0 1px;
        }

        .fc-event {
            margin-top: 2px;
            margin-bottom: 1px;
            border: 0;
            padding: 8px 11px;
            color: $color-text;
            border-radius: 0;
            $event-color: #555;
            background-color: rgba($event-color, 0.3);
            border-left: 2px solid $event-color;

            .fc-time {
                margin-bottom: 3px;
            }
        }

        @each $type, $map in $possible-types {
            .tt-Calendar__event--#{$type} {
                $event-color: map-get($map, event-color);

                &.fc-event {
                    background-color: rgba($event-color, 0.5);
                    border-left: 2px solid $event-color;
                }

                .fc-event-dot {
                    background-color: $event-color;
                }
            }
        }

        .tt-Calendar__event--LESSON_DATE.fc-event:before {
            content: '';
            position: absolute;
            top: 8px;
            right: 8px;
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: url('images/icons/calendar-add.svg');
        }
        .tt-Calendar__event--LESSON_DATE.fc-event:hover:before {
            background: url('images/icons/calendar-remove.svg');
        }

        @media screen and (min-width: $breakpoint-tablet) {
            .fc-toolbar {
                margin-bottom: getSpacing(2);

                min-width: 720px;
            }
            .fc-view {
                min-width: 720px;
            }
        }

        @media screen and (min-width: $breakpoint-min) {
            .fc-toolbar {
                h2 {
                    font-size: 14px;
                    margin-top: getSpacing(1);
                }
                .fc-prev-button,
                .fc-next-button {
                    margin-top: 4px;
                }
                .fc-today-button,
                .fc-listWeek-button {
                    font-size: 14px;
                    height: 41px;
                    padding: 11px 18px;
                }

                .fc-center {
                    display: inline-block;
                }
            }
        }
    }

    @each $type, $map in $possible-types {
        &--default-event--#{$type} {
            .fc {
                .fc-helper {
                    $event-color: map-get($map, event-color);
                    background-color: rgba($event-color, 0.3);
                    border-left: 2px solid $event-color;
                }
            }
        }
    }

    &--is-loading {
        .fc {
            opacity: 0.3;
        }

        .tt-Spinner {
            opacity: 1;
        }
    }

    &--is-general {
        .fc {
            padding-top: 18px;
        }

        .fc-toolbar {
            display: none;
        }

        .fc-list-heading-alt {
            display: none;
        }
    }

    &--is-touch {
        .fc {
            .fc-toolbar {
                display: block;
                padding-bottom: getSpacing(2);
            }
        }

        .fc-scroller {
            height: auto !important;
        }
    }
}
