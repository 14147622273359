.tt-FieldGroup {
    @import 'stylesheets/variables/spacing.scss';
    @import 'stylesheets/variables/breakpoints.scss';
    @import 'stylesheets/variables/colors.scss';

    width: 100%;
    list-style-type: none;

    &__wrapper {
        width: 100%;
    }

    &__title,
    &__list {
        vertical-align: top;
    }

    &__title {
        display: block;
        width: 256px;
        font-size: 16px;
        padding-bottom: getSpacing(1.5);
    }

    &__list {
        width: 100%;
    }

    .tt-Field {
        width: 100%;
    }

    .tt-ValidationError {
        margin-top: getSpacing(1);
    }

    &--is-removable {
        position: relative;

        .tt-FieldGroup__remove-button {
            height: 32px;
            padding: 0 7px;
            top: 12px;
            right: 12px;
            position: absolute;
        }
    }

    &--is-form {
        .tt-FieldGroup__wrapper {
            margin-bottom: -1 * getSpacing(1);
        }

        .tt-Field {
            margin-bottom: getSpacing(1);
        }

        .tt-Field__title {
            padding-top: getSpacing(1);
            padding-bottom: getSpacing(1);
            line-height: 18px;
            display: block;
        }

        .tt-Field__content {
            display: block;
        }
    }

    &--is-inset-group {
        padding: getSpacing(2);
        background: #f2f4f5;

        .tt-Field {
            margin-bottom: 6px;
        }

        .tt-Field:first-child .tt-Field__title {
            padding-top: 0;
        }

        .tt-Field__title {
            padding-left: 0;
        }

        &.tt-FieldGroup--is-form {
            .tt-Field:first-child .tt-Field__title {
                padding-top: getSpacing(1);
            }
        }
    }

    &--single-field {
        .tt-Field__title {
            padding-bottom: 0;
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        &__title,
        &__list {
            display: inline-block;
        }

        &__title {
            padding-bottom: 0;
            padding-top: 5px;
            padding-right: getSpacing(1);
        }

        &__title + &__list {
            width: calc(100% - 256px);
        }

        &--is-form {
            .tt-Field__wrapper {
                display: table;
            }

            .tt-Field__title,
            .tt-Field__content {
                display: table-cell;
            }

            .tt-Field__content {
                padding-top: 0;
                padding-bottom: 0;
                vertical-align: middle;
            }

            .tt-Field--is-compact {
                .tt-Field__title,
                .tt-Field__content {
                    display: block;
                }
            }
        }

        &--is-inset-group {
            margin-bottom: getSpacing(1);

            .tt-Field__title {
                width: 33.5%;
            }

            .tt-Field:first-child .tt-Field__title .tt-Field__image {
                padding-top: getSpacing(1);
            }

            &.tt-FieldGroup--is-last-field-in-modal {
                margin-bottom: 0;
            }
        }

        &--has-inline-edit {
            .tt-FieldGroup__list {
                position: relative;

                .tt-Field--inline-edit {
                    position: absolute;
                    top: -9px;
                    right: 0;
                    width: auto;
                    opacity: 0;
                    pointer-events: none;
                }
            }

            &:hover {
                .tt-Field--inline-edit {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }

        &--separate-fields {
            .tt-Field {
                + .tt-Field:not(.tt-Field--inline-edit) {
                    border-top: 1px solid $color-border;
                    margin-top: getSpacing(2);
                    padding-top: getSpacing(2);
                }
            }
        }

        &--is-compact {
            .tt-Field:first-child .tt-Field__title {
                padding-top: 0;
            }

            .tt-Field__content {
                padding: 0;
            }

            .tt-Field {
                margin-bottom: 0;

                & + .tt-Field {
                    margin-bottom: getSpacing(0.5);
                }
            }
        }
    }
}
