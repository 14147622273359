@import 'stylesheets/variables/breakpoints.scss';

.tt-CenterView {
    display: table;
    width: 100%;
    height: 100%;

    &__vertical-center {
        display: table-cell;
        vertical-align: middle;
    }

    &__horizontal-center {
        margin-left: auto;
        margin-right: auto;
    }

    &--small {
        .tt-CenterView__horizontal-center {
            max-width: 288px;
        }
    }
}
