.tt-LessonsSchedule {
    @import 'stylesheets/variables/spacing.scss';
    @import 'stylesheets/variables/colors.scss';

    width: auto;
    background: $color-grey-background;
    border: 1px solid $color-border;
    border-radius: 2px;
    padding: (getSpacing(3) - 5px) getSpacing(3);
    font-size: 14px;

    .tt-List {
        text-align: left;
        margin: -1 * getSpacing(2);

        .tt-ListItem {
            margin-right: getSpacing(4);
            margin-top: 0;
            margin-left: 0;
            padding: getSpacing(2);

            .tt-LessonsSchedule__LessonDate {
                font-weight: 600;
            }

            .tt-Subtle {
                font-size: inherit;
            }

            ul {
                margin-top: getSpacing(1);
                .tt-Subtle {
                    font-style: italic;
                }
            }
        }
    }
}
