@import 'stylesheets/variables/colors.scss';
@import 'stylesheets/variables/spacing.scss';

.tt-MobileNavLink {
    height: 58px;

    .tt-Link {
        text-align: left;
        color: $color-blue-dark;
        opacity: 0.6;
        height: 58px;
        display: block;
        padding: getSpacing(1) getSpacing(2);
        line-height: 36px;
        font-weight: 600;
        font-size: 14px;

        &:hover {
            text-decoration: none;
            opacity: 1;

            .tt-Link__content {
                text-decoration: none;
            }
        }

        &--is-active {
            opacity: 1;
            background-color: $color-primary-active;
        }
    }
}
