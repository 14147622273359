@import 'stylesheets/variables/spacing.scss';
@import 'stylesheets/variables/colors.scss';
@import 'stylesheets/variables/breakpoints.scss';

.tt-DrawerHeader {
    padding: getSpacing(2) + 6px getSpacing(1.5);
    background-color: #fff;
    display: block;
    border-bottom: 1px solid $color-border;

    &__title,
    &__content {
        display: inline-block;
        vertical-align: top;
    }

    &__title {
        line-height: 41px;
    }

    &__content {
        float: right;
        width: auto;

        .tt-ListItem {
            margin-left: getSpacing(1);
        }
    }

    &__content-close-button {
        &.tt-Button {
            border: none;

            &:hover {
                border: none;
                background: transparent;
                color: $color-grey-subtle;
                .tt-Icon {
                    color: #2d373f;
                }
            }
            &:active {
                box-shadow: none;
            }
        }
    }

    &::after {
        content: '';
        display: block;
        clear: both;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        height: 85px;
        padding: getSpacing(2) getSpacing(3);
    }
}
