@import 'stylesheets/variables/breakpoints.scss';

.tt-Timeline {
    width: 100%;

    .tt-TimelineInput {
        width: 100%;
    }

    .tt-TimelineMessage {
        margin-bottom: getSpacing(1);
    }

    .tt-FieldGroup {
        padding: 0;
        margin-bottom: getSpacing(2);

        .tt-Field__error {
            padding-left: 0;
        }
    }

    @media screen and (min-width: 768px) {
        .tt-FieldCollection .tt-FieldGroup:first-child {
            padding-top: 0;
        }
    }
}
